import Radium from 'radium'
import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import compose from 'recompose/compose'
import Icon from 'components/icon'
import Anchor from 'components/anchor'

import styles from './styles'

export default compose(withTranslation(), Radium)(InputLabel)

function InputLabel(props) {
  const {
    hideLabel = false,
    id,
    label,
    readOnly = false,
    required = false,
    showErrorMessage = false,
    small = false,
    alignTop = false,
    t,
    tooltip,
    flexDirection,
    anchorDirection,
  } = props

  // NOTE its useful to know the difference between readonly and
  // disabled http://stackoverflow.com/a/7730719
  const isOptional = required !== null && !required && !readOnly

  const isVisible = label && !hideLabel
  const rootStyles = [styles.root]

  if (!isVisible) {
    return null
  }

  if (flexDirection) {
    rootStyles.push({ flexDirection: flexDirection })
  }

  const tooltipEl = tooltip ? (
    <Anchor tooltip={tooltip} anchorDirection={anchorDirection}>
      <Icon name="help" theme={styles.guide} />
    </Anchor>
  ) : null

  const errorStyles = [
    showErrorMessage ? styles.error : null,
    small ? styles.small : null,
  ]

  return (
    <label
      htmlFor={id}
      style={[...rootStyles, errorStyles, alignTop && styles.alignTop]}
    >
      {label}
      {/* <Trans i18nKey="schedules.repeatTooltip.message">
        The <i>No Repeat</i> option is a one-time occurrence and defaults to use
        the location service hours.
        <br />
        The Repeat on time since last activity completion option bases the start
        timer on the time of the activity was completed.
        <br />
        The Repeat on set time intervals option bases the start time on regular
        intervals starting from the location open service hour time.
      </Trans> */}
      {tooltipEl}
      {isOptional && <span style={[styles.help]}>{t('labelOptional')}</span>}
    </label>
  )
}
