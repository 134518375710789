import { chain } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import React, { useMemo } from 'react'
import { Block } from 'components/common'
import Select from '../select'
import { useSelector } from 'react-redux'

const areaModule = getModule('areas')

export default function LocationGroupSelect(props) {
  const {
    className,
    clearable,
    dataTestId = 'location-group-field',
    disabled,
    disableSelectedList,
    error,
    multi,
    onBlur,
    onChange,
    placeholder,
    required,
    value,
    type,
  } = props

  const classes = error ? `${className}, error` : className
  const isObjectValue = value && value.length && typeof value[0] === 'object'

  const locationGroups = useSelector(state => {
    const areaSelectors = areaModule.selectors(state)()
    return areaSelectors.byType('location-group')
  })

  const options = useMemo(
    () =>
      chain(locationGroups)
        .map(locationGroup => ({
          label: locationGroup.entity.name,
          search: locationGroup.entity.search,
          value: locationGroup.entity._id,
          locationCount: locationGroup.entity.locationCount,
        }))
        .sortBy('label')
        .value(),
    [locationGroups]
  )

  return (
    <Block position={'relative'}>
      <Select
        className={classes}
        clearable={clearable}
        dataTestId={dataTestId}
        disabled={disabled}
        disableSelectedList={disableSelectedList}
        multi={multi}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        required={required}
        value={isObjectValue ? value.map(val => val.id) : value}
        type={type}
      />
    </Block>
  )
}
