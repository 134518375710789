import React from 'react'
import Select from '../select'
import useAudits from './useAudits'
import { Resource } from '../../../types/resource'
import { Option } from '../../../types/option'

interface Props {
  className?: string
  clearable?: boolean
  dataTestId?: string
  disabled?: boolean
  error?: boolean
  multi?: boolean
  placeholder?: string
  required?: boolean
  input: {
    name: string
    onBlur: any
    onChange: any
    onDragStart: any
    onDrop: any
    onFocus: any
    value: string | string[]
  }
}

const AuditSelect = (props: Props): JSX.Element => {
  const {
    className,
    clearable,
    dataTestId,
    disabled,
    error,
    multi,
    placeholder,
    required,
    input,
  } = props

  const audits: Resource[] = useAudits()

  const { value, onChange, onBlur } = input

  const classes = error ? `${className}, error` : className

  const auditOptions: Option[] = audits.map(audit => ({
    label: audit.entity.title,
    value: audit.entity._id,
  }))

  return (
    <Select
      className={classes}
      clearable={clearable}
      dataTestId={dataTestId}
      disabled={disabled}
      multi={multi}
      onBlur={onBlur}
      onChange={onChange}
      options={auditOptions}
      placeholder={placeholder}
      required={required}
      type="audit"
      value={value}
    />
  )
}

export default AuditSelect
