import { sortBy } from 'lodash'
import { List } from 'react-virtualized'
import { compose, pure, withHandlers, withProps } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import { Block } from 'components/common'

import Container from '../container'
import DropdownPlaceholder from '../dropdown-placeholder'
import DropdownSearch from '../dropdown-search'
import DropdownStatus from '../dropdown-status'
import DropdownClear from '../dropdown-clear'
import Tag from '../tag'

const MAX_HEIGHT = 190
const ROW_HEIGHT = 42

export default compose(
  withProps(({ selectedItems, shouldSort = true }) => ({
    sortedSelectedItems: shouldSort
      ? sortBy(selectedItems, 'label')
      : selectedItems,
  })),
  withHandlers({
    rowRenderer,
  }),
  pure
)(SelectDropdown)

function SelectDropdown(props) {
  const {
    clearable,
    disabled,
    disableSelectedList,
    getInputProps,
    handleBlur,
    handleClear,
    hasError,
    highlightedIndex,
    inputValue,
    isLoading,
    isOpen,
    isSearching,
    items = [],
    multi,
    placeholder,
    readOnly,
    removeItem,
    rowRenderer,
    selectedItems = [],
    selectItemAtIndex,
    setHighlightedIndex,
    sortedSelectedItems,
    width,
    value,
    widthText,
    errorMessage,
  } = props

  const itemCount = items.length
  const selectedItemCount = sortedSelectedItems.length

  /**
   * NOTE: Tried to debug this one, but couldn't get to the bottom of it.
   *
   * If the list is the full width prop value, when you select an item,
   * the width constantly increases by 2px and eventually renders
   * off the screen.
   *
   * There were a few components causing the issue, which had width=100%.
   * Looking at <List /> in react-virtualized, they auto set the width
   * to 100% for rows, which may be causing the issue here...?
   *
   * https://github.com/bvaughn/react-virtualized/blob/07e916701f7210ef819f19d63f4e96a2c999b34e/source/List/List.js#L221
   *
   * Update: This issue continues to reproduce for multiple select field
   * a workaround is to reduce the width in 10px
   * more info: https://github.com/Lighthouse-io/web/pull/1635/
   */

  const listWidth = width - 10

  const totalHeight = selectedItemCount * ROW_HEIGHT
  const height = totalHeight > MAX_HEIGHT ? MAX_HEIGHT : totalHeight

  const elementColor = hasError
    ? colors.red.normal
    : disableSelectedList || selectedItems.length === 0
    ? colors.gray.light
    : colors.gray.dark

  const excludeMultiResults = !isOpen && (!multi || !selectedItemCount)

  /**
   * NOTE: Only show placeholder if the dropdown is closed and:
   *   1. No value is selected
   *   2. A single value is selected, and multi is false
   *   3. The selected list is disabled
   */
  const showPlaceholder =
    excludeMultiResults || (!isOpen && disableSelectedList)
  const showResults = !disableSelectedList && multi && selectedItems.length > 0
  const showSearch = !disabled && !readOnly && isOpen
  const showStatus = !readOnly && excludeMultiResults
  const showClear = showStatus && clearable && selectedItems.length > 0

  return (
    <Container
      disabled={disabled}
      hasError={hasError}
      isLoading={isLoading}
      isOpen={isOpen}
      readOnly={readOnly}
      width={width}
      errorMessage={errorMessage}
    >
      {showPlaceholder && (
        <DropdownPlaceholder
          color={elementColor}
          disableSelectedList={disableSelectedList}
          isLoading={isLoading}
          multi={multi}
          placeholder={placeholder}
          readOnly={readOnly}
          selectedItems={sortedSelectedItems}
          widthText={widthText}
        />
      )}
      {showResults && (
        <Block paddingTop={2} paddingBottom={2}>
          <List
            height={height}
            rowCount={selectedItemCount}
            rowHeight={ROW_HEIGHT}
            rowRenderer={rowRenderer}
            width={listWidth}
            /* NOTE this is necessary to force re-renders when the content
            changes, but the length of the items is the same */
            sortedSelectedItems={sortedSelectedItems}
          />
        </Block>
      )}
      {showSearch && (
        <DropdownSearch
          color={elementColor}
          getInputProps={getInputProps}
          handleBlur={handleBlur}
          highlightedIndex={highlightedIndex}
          inputValue={inputValue}
          isLoading={isLoading}
          itemCount={itemCount}
          isSearching={isSearching}
          placeholder={placeholder}
          removeItem={removeItem}
          selectedItems={sortedSelectedItems}
          selectItemAtIndex={selectItemAtIndex}
          setHighlightedIndex={setHighlightedIndex}
          value={value}
        />
      )}
      {showClear && <DropdownClear handleClear={handleClear} />}
      {showStatus && <DropdownStatus isLoading={isLoading} isOpen={isOpen} />}
    </Container>
  )
}

SelectDropdown.propTypes = {
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSelectedList: PropTypes.bool,
  getInputProps: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  highlightedIndex: PropTypes.number,
  inputValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  removeItem: PropTypes.func,
  selectedItems: PropTypes.array,
  selectItemAtIndex: PropTypes.func.isRequired,
  setHighlightedIndex: PropTypes.func.isRequired,
}

function rowRenderer({ disabled, readOnly, removeItem, sortedSelectedItems }) {
  return ({ index, key, style }) => {
    const item = sortedSelectedItems[index]
    const { height, left, position, top } = style
    return (
      <Block
        height={height}
        key={key}
        left={left}
        position={position}
        top={top}
      >
        <Tag
          disabled={disabled}
          item={item}
          onClick={() => removeItem({ item })}
          readOnly={readOnly}
        />
      </Block>
    )
  }
}
