import { TFunction } from 'i18next'
import { get, isEqual, Dictionary } from 'lodash'

interface TemplateLocationObject {
  id: string
  addedAt: Date
  addedBy: string
  name: string
}

interface GetLocationReferenceProps {
  entity: { location?: string[]; zones: string[] }
  locationsCache: Dictionary<any>
  zonesCache: Dictionary<any>
  showZone?: boolean
  t: TFunction
}

interface GetLocationReferenceFlaggedProps {
  entity: {
    zones: string[]
    locations: TemplateLocationObject[]
    locationGroups: TemplateLocationObject[]
  }
  locationsCache: Dictionary<any>
  zonesCache: Dictionary<any>
  showZone?: boolean
  t: TFunction
}

interface GetLocationGroupReferenceFlaggedProps {
  entity: {
    locationGroups: TemplateLocationObject[]
    locations: TemplateLocationObject[]
  }
  t: TFunction
}

export function getLocationReference({
  locationsCache,
  zonesCache,
  entity,
  showZone = true,
  t,
}: GetLocationReferenceProps): string {
  // NOTE Do not use destructuring fallbacks here as values can be null

  const locations = entity.location || []
  const zones = entity.zones || []
  const locationsCount = locations.length
  const isSingleLocation = isEqual(locations.length, 1)
  const isSingleZone = isEqual(zones.length, 1)
  const isMultipleLocation = locationsCount > 1

  const zoneName = isSingleZone
    ? get(zonesCache, `${zones.toString()}.name`) || t('labelUnknownLocation')
    : null

  const locationName = isSingleLocation
    ? get(locationsCache, `${locations.toString()}.name`) ||
      t('labelUnknownLocation')
    : null

  if (isSingleLocation && isSingleZone && showZone) {
    return `${locationName} - ${zoneName}`
  } else if (isSingleLocation) {
    return locationName
  } else if (isMultipleLocation) {
    return t('labelXLocations', { count: locationsCount })
  }
  return t('labelAllLocations')
}

export function getLocationReferenceFlagged({
  entity,
  locationsCache,
  t,
}: GetLocationReferenceFlaggedProps): string {
  const locations = entity.locations || []
  const locationGroups = entity.locationGroups || []
  const locationsCount = locations.length
  const isSingleLocation = isEqual(locations.length, 1)
  const isMultipleLocation = locationsCount > 1

  if (isSingleLocation) {
    return locations[0].name || t('labelUnknownLocation')
  } else if (isMultipleLocation) {
    return t('labelXLocations', { count: locationsCount })
  } else if (locationsCount === 0 && locationGroups.length > 0) {
    return `-`
  }
  return t('labelAllLocations')
}

export function getLocationGroupReferenceFlagged({
  entity,
  t,
}: GetLocationGroupReferenceFlaggedProps): string {
  const locations = entity.locations || []
  const locationGroups = entity.locationGroups || []
  const locationGroupsCount = locationGroups.length
  const isSingleLocationGroup = locationGroups.length === 1
  const isMultipleLocationGroup = locationGroups.length > 1

  const locationGroupName = isSingleLocationGroup
    ? locationGroups[0].name || t('labelUnknownLocationGroup')
    : null

  if (isSingleLocationGroup) {
    return locationGroupName
  } else if (isMultipleLocationGroup) {
    return t('labelXLocationGroups', { count: locationGroupsCount })
  } else if (locations.length > 0 && locationGroups.length === 0) {
    return `-`
  }
  return t('labelAllLocationGroups')
}
