import { colors as brandColors } from 'config/theme/brand'
import colors from 'config/theme/colors'

export default {
  root: {
    color: brandColors.text,
    display: 'flex',
    flex: '0 0 150px',
    fontSize: '14px',
    marginRight: '20px',
    flexDirection: 'column',
  },

  small: {
    flex: '0 0 110px',
    fontSize: '12px',
  },

  alignTop: {
    paddingTop: '10px',
  },

  help: {
    color: colors.gray.light,
    display: 'block',
    fontSize: '80%',
  },

  guide: {
    color: colors.gray.light,
    fontSize: '16px',
    fontWeight: 200,
    paddingLeft: '5px',
    float: 'right',
  },

  error: {
    color: colors.red.normal,
  },
}
