import { filter, includes, map } from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Field, FieldArray } from 'redux-form'

import { Block, Flex, Span } from 'components/common'
import FlagsHOC from 'components/flags/hoc'
import {
  InputCheckbox,
  InputSelect,
  InputTimeZoneSelect,
  FieldGroup,
  TemplateSelect,
  AuditSelect,
} from 'components/form'
import { Table } from 'components/table-basic'
import styles from './styles'
import { TargetSelect } from './TargetSelect'

const NOTIFICATION_CHANNEL_MAP = {
  'audit-entry-new': {
    label: 'New Audit',
    labelT: 'labelNewAudit',
    endpoints: ['email', 'push', 'sms'],
  },
  'audit-entry-update': {
    label: 'Updated Audit',
    labelT: 'labelUpdatedAudit',
    endpoints: ['email', 'push', 'sms'],
  },
  'daily-location-report': {
    label: 'Daily Location Report',
    labelT: 'labelDailyLocationReport',
    endpoints: ['email'],
  },
  'daily-shift-report': {
    label: 'Daily Shift Report',
    labelT: 'labelDailyShiftReport',
    endpoints: ['email'],
  },
  duress: {
    label: 'Duress Alerts',
    labelT: 'labelDuressAlerts',
    endpoints: ['email', 'push', 'sms'],
  },
  'exception-opened': {
    label: 'Exception Opened',
    labelT: 'labelExceptionOpened',
    endpoints: ['push'],
  },
  'exception-resolved': {
    label: 'Exception Closed',
    labelT: 'labelExceptionClosed',
    endpoints: ['push'],
  },
  'issue-new': {
    label: 'New Issues, assigned to me',
    labelT: 'labelNewIssue',
    endpoints: ['email', 'push', 'sms'],
  },
  'issue-update': {
    label: 'Updated Issues, assigned to me',
    labelT: 'labelUpdatedIssue',
    endpoints: ['email', 'push', 'sms'],
  },
  'issue-assignee': {
    label: 'New And Updated Issues, assigned to me',
    labelT: 'labelNewAndUpdatedIssuesAssignedToMe',
    endpoints: ['email', 'push', 'sms'],
  },
  'shift-end': {
    label: 'Shift Finished',
    labelT: 'labelShiftFinished',
    endpoints: ['email', 'push', 'sms'],
  },
  'shift-start': {
    label: 'ShiftStarted',
    labelT: 'labelShiftStarted',
    endpoints: ['email', 'push', 'sms'],
  },
  'task-entry-new': {
    label: 'New Task',
    labelT: 'labelNewTask',
    endpoints: ['email', 'push', 'sms'],
  },
  'task-entry-update': {
    label: 'Updated Task',
    labelT: 'labelUpdatedTask',
    endpoints: ['email', 'push', 'sms'],
  },
}

const hourOptions = [
  { label: '12am', value: 0 },
  { label: '1am', value: 1 },
  { label: '2am', value: 2 },
  { label: '3am', value: 3 },
  { label: '4am', value: 4 },
  { label: '5am', value: 5 },
  { label: '6am', value: 6 },
  { label: '7am', value: 7 },
  { label: '8am', value: 8 },
  { label: '9am', value: 9 },
  { label: '10am', value: 10 },
  { label: '11am', value: 11 },
  { label: '12pm', value: 12 },
  { label: '1pm', value: 13 },
  { label: '2pm', value: 14 },
  { label: '3pm', value: 15 },
  { label: '4pm', value: 16 },
  { label: '5pm', value: 17 },
  { label: '6pm', value: 18 },
  { label: '7pm', value: 19 },
  { label: '8pm', value: 20 },
  { label: '9pm', value: 21 },
  { label: '10pm', value: 22 },
  { label: '11pm', value: 23 },
]

const summaryOptions = [
  {
    label: 'Summary Report',
    labelT: 'labelSummaryReport',
    value: false,
  },
  {
    label: 'Summary Report Form Submissions',
    labelT: 'labelSummaryReportFormSubmissions',
    value: true,
  },
]

class NotificationFields extends Component {
  constructor(props) {
    super(props)

    this.renderChannels = this.renderChannels.bind(this)
  }

  render() {
    const { readOnly = false, t } = this.props

    return (
      <FieldGroup>
        <Table>
          <thead>
            <tr>
              <th style={[styles.headerCell, styles.alignLeft]}>
                {t('labelActivity')}
              </th>
              <th style={[styles.headerCell, styles.alignCenter]}>
                {t('labelEnabled')}
              </th>
              <th style={[styles.headerCell, styles.alignLeft]}>
                {t('labelNotification')}
              </th>
            </tr>
          </thead>
          <FieldArray
            name="preferences.notifications.channels"
            component={this.renderChannels}
            readOnly={readOnly}
            rerenderOnEveryChange
          />
        </Table>
      </FieldGroup>
    )
  }

  renderChannels({ fields, readOnly }) {
    const { flags, t } = this.props
    const { notificationsSms: isSmsEnabled = false } = flags

    const endpointOptions = [
      { label: t('labelEmail'), value: 'email' },
      { label: t('labelPush'), value: 'push' },
    ]

    if (isSmsEnabled) {
      endpointOptions.push({ label: t('labelSMS'), value: 'sms' })
    }

    const channels = fields.map((formPath, index, fields) => {
      const { enabled, name } = fields.get(index)

      const channelOptions = NOTIFICATION_CHANNEL_MAP[name]

      const channelEndpointOptions = filter(endpointOptions, endpoint =>
        includes(channelOptions.endpoints, endpoint.value)
      )

      const isAudit = name.startsWith('audit-')
      const isDailyShiftReport = name === 'daily-shift-report'
      const isException = name.startsWith('exception-')
      const isIssue = name.startsWith('issue-')
      const isTask = name.startsWith('task-')

      // NOTE: hide daily shift report if no flag
      if (isDailyShiftReport && !flags.shiftReport) {
        return null
      }

      // NOTE: add email endpoint option if flag
      if (isException && flags.exceptionEmails) {
        channelEndpointOptions.push({ label: 'Email', value: 'email' })
      }

      return (
        <tr key={index}>
          <td style={styles.bodyCell} width="30%">
            {t(channelOptions.labelT)}
          </td>
          <td style={styles.bodyCell} width="20%">
            <Field
              name={`${formPath}.enabled`}
              component={InputCheckbox}
              readOnly={readOnly}
              style={styles.alignCenter}
            />
          </td>
          <td style={styles.bodyCell} width="60%">
            <Flex flexDirection="column">
              <Block>
                <Field
                  clearable
                  component={InputSelect}
                  disabled={!enabled}
                  multi
                  name={`${formPath}.endpoints`}
                  placeholder={t('placeholder.selectANotificationMethod')}
                  options={channelEndpointOptions}
                  readOnly={readOnly}
                  required
                  small
                />
              </Block>
              {name === 'daily-location-report' ? (
                <Block>
                  <Flex alignItems="center">
                    <Block marginBottom={15}>
                      <Span>{t('labelSendAfter')}:</Span>
                    </Block>
                    <Block paddingLeft={15} paddingRight={15}>
                      <Field
                        clearable={false}
                        component={InputSelect}
                        disabled={!enabled}
                        name={`${formPath}.options.hours`}
                        options={hourOptions}
                        readOnly={readOnly}
                        required={!!enabled}
                        shouldSort={false}
                      />
                    </Block>
                    <Block flexGrow={1}>
                      <Field
                        clearable={false}
                        component={InputTimeZoneSelect}
                        disabled={!enabled}
                        label=""
                        name={`${formPath}.options.timezone`}
                        readOnly={readOnly}
                        required={!!enabled}
                        small
                      />
                    </Block>
                  </Flex>
                  <Flex alignItems="center">
                    <Block flexGrow={1}>
                      <Field
                        clearable={false}
                        component={InputSelect}
                        disabled={!enabled}
                        name={`${formPath}.options.formSubmissions`}
                        options={map(summaryOptions, ({ labelT, value }) => ({
                          label: t(labelT),
                          value,
                        }))}
                        readOnly={readOnly}
                        required={!!enabled}
                        shouldSort={false}
                        small
                      />
                    </Block>
                  </Flex>
                  <Flex alignItems="center">
                    <Block>
                      <Field
                        name={`${formPath}.options.skipNoActivity`}
                        component={InputCheckbox}
                        readOnly={readOnly}
                        style={styles.alignCenter}
                      />
                    </Block>
                    <Block marginLeft={5} marginBottom={15}>
                      <Span>{t('labelSkipLocationsWithNoActivity')}</Span>
                    </Block>
                  </Flex>
                </Block>
              ) : null}
              {name === 'daily-shift-report' ? (
                <Block>
                  <Flex alignItems="center">
                    <Block marginBottom={15}>
                      <Span>{t('labelSendAfter')}:</Span>
                    </Block>
                    <Block paddingLeft={15} paddingRight={15}>
                      <Field
                        clearable={false}
                        component={InputSelect}
                        disabled={!enabled}
                        name={`${formPath}.options.hours`}
                        options={hourOptions}
                        readOnly={readOnly}
                        required={!!enabled}
                        shouldSort={false}
                      />
                    </Block>
                    <Block flexGrow={1}>
                      <Field
                        clearable={false}
                        component={InputTimeZoneSelect}
                        disabled={!enabled}
                        label=""
                        name={`${formPath}.options.timezone`}
                        readOnly={readOnly}
                        required={!!enabled}
                        small
                      />
                    </Block>
                  </Flex>
                  <Flex alignItems="center">
                    <Block flexGrow={1}>
                      <Field
                        clearable={false}
                        component={InputSelect}
                        disabled={!enabled}
                        name={`${formPath}.options.formSubmissions`}
                        options={summaryOptions}
                        readOnly={readOnly}
                        required={!!enabled}
                        shouldSort={false}
                        small
                      />
                    </Block>
                  </Flex>
                </Block>
              ) : null}
              {isIssue && (
                <Flex alignItems="center">
                  <Block flexGrow={1}>
                    <Field
                      multi
                      clearable={false}
                      component={TemplateSelect}
                      disabled={!enabled}
                      name={`${formPath}.options.templates`}
                      readOnly={readOnly}
                      required={!!enabled}
                      shouldSort={false}
                      small
                      placeholder={t('placeholder.selectIssueTemplate')}
                      type="issue"
                    />
                  </Block>
                </Flex>
              )}
              {isTask && (
                <Flex alignItems="center">
                  <Block flexGrow={1}>
                    <Field
                      multi
                      clearable={false}
                      component={TemplateSelect}
                      disabled={!enabled}
                      name={`${formPath}.options.templates`}
                      readOnly={readOnly}
                      required={!!enabled}
                      shouldSort={false}
                      small
                      placeholder={t('placeholder.selectTaskTemplate')}
                      type="task"
                    />
                  </Block>
                </Flex>
              )}
              {isAudit && (
                <Flex alignItems="center">
                  <Block flexGrow={1}>
                    <Field
                      multi
                      clearable={false}
                      component={AuditSelect}
                      disabled={!enabled}
                      name={`${formPath}.options.templates`}
                      readOnly={readOnly}
                      required={!!enabled}
                      shouldSort={false}
                      small
                      placeholder={t('placeholder.selectAuditTemplate')}
                    />
                    <Field
                      clearable={false}
                      component={TargetSelect}
                      disabled={!enabled}
                      name={`${formPath}.options.auditTargets`}
                    />
                  </Block>
                </Flex>
              )}
            </Flex>
          </td>
        </tr>
      )
    })

    return <tbody>{channels}</tbody>
  }
}

export default compose(FlagsHOC, withTranslation())(NotificationFields)
