import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex, Block, Span } from 'components/common'
import colors from 'config/theme/colors'

export default pure(SelectContainer)

function SelectContainer({
  children,
  disabled,
  isLoading,
  hasError,
  isOpen,
  readOnly,
  errorMessage,
}) {
  const backgroundColor =
    (disabled && !readOnly) || (isLoading && !isOpen)
      ? colors.gray.lightest
      : colors.white

  const borderColor = readOnly
    ? 'transparent'
    : hasError
    ? colors.red.normal
    : colors.gray.lighter
  const borderRadius = isOpen ? 0 : 4

  return (
    <Block>
      <Flex
        alignItems="center"
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderBottomLeftRadius={borderRadius}
        borderBottomRightRadius={borderRadius}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        borderStyle="solid"
        borderWidth={1}
        cursor={readOnly ? 'cursor' : 'pointer'}
        flexWrap="wrap"
        marginTop={-1}
        minHeight={48}
        outline="none"
        overflow="hidden"
        transition="0.3s"
      >
        {children}
      </Flex>
      {hasError && (
        <Span color={colors.red.normal} fontSize="10px" fontStyle="italic">
          {errorMessage}
        </Span>
      )}
    </Block>
  )
}

SelectContainer.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  readOnly: PropTypes.bool,
}
