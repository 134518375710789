import React from 'react'
import Select from '../select'
import { getTemplateOptions } from './helpers'
import useTemplates from './useTemplates'
import { Resource } from '../../../types/resource'
import { Option } from '../../../types/option'

interface Props {
  type: string
  className?: string
  clearable?: boolean
  dataTestId?: string
  disabled?: boolean
  error?: boolean
  multi?: boolean
  placeholder?: string
  required?: boolean
  input: {
    name: string
    onBlur: any
    onChange: any
    onDragStart: any
    onDrop: any
    onFocus: any
    value: string | string[]
  }
}

const TemplateSelect = (props: Props): JSX.Element => {
  const {
    className,
    clearable,
    dataTestId,
    disabled,
    error,
    input,
    multi,
    placeholder,
    required,
    type,
  } = props

  const templates = useTemplates()

  const { value, onChange, onBlur } = input

  const classes = error ? `${className}, error` : className

  const templateOptions: Option[] = getTemplateOptions(templates, type)

  return (
    <Select
      className={classes}
      clearable={clearable}
      dataTestId={dataTestId}
      disabled={disabled}
      multi={multi}
      onBlur={onBlur}
      onChange={onChange}
      options={templateOptions}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
    />
  )
}

export default TemplateSelect
