import { LocaleResource } from '.'

const enUS: LocaleResource = {
  'activityPanel.filter.allActivity': 'All Activity',
  'activityPanel.filter.audits': 'Audits',
  'activityPanel.filter.exceptions': 'Exceptions',
  'activityPanel.filter.issues': 'Issues',
  'activityPanel.filter.shifts': 'Shifts',
  'activityPanel.filter.tasks': 'Tasks',
  'activityPanel.rowContent.exception.closed': 'Closed: {{time}}',
  'activityPanel.rowContent.exception.closedDuration':
    'Closed: {{time}} ({{duration}})',
  'activityPanel.rowContent.exception.duration': 'Duration: {{duration}}',
  'activityPanel.rowContent.exception.open': 'Exception Open',
  'activityPanel.rowContent.exception.opened': 'Opened: {{time}}',
  'activityPanel.rowContent.shiftEnd': 'Shift Finished',

  'alert.message.exportConfirmationConfirm':
    'Are you sure you wish to export the selected {{totalCount}} records?',
  'alert.message.exportConfirmationError':
    'There was a problem exporting your data. Please try again or, if the problem persists, contact support',
  'alert.message.exportConfirmationSuccess':
    'Data export created successfully. The export will be available from the <underline>Reports > Exports</underline> tab shortly.',
  'alert.message.exportConfirmationWarning':
    'Export size too large. Please filter results to less than 500,000 records.',
  'alert.message.exportConfirmationWarningPdf':
    'Export size too large. Please filter results to less than 1,000 records.',
  'alert.message.pageNotFound':
    'The Page you were looking for could not be found. Please contact Support.',
  'alert.message.profileSuccessfullyUpdated':
    'Your profile has been successfully updated',
  'alert.message.requiredDataMissing': 'Required data missing',
  'alert.message.selectBeforeAddingSignal':
    'Select a location, building or geofence before adding a signal',
  'alert.message.signalMustBeInSelectedArea':
    'Signal must be created in area selected',
  'alert.message.unableToCreateSignal':
    'Unable to create signal. Please contact support.',
  'alert.message.unableToExportPDF':
    'Unable to export PDF. Please try again or contact support.',
  'alert.networkError':
    'There was a problem with the request because of a Network Error',
  'alert.noItemsCouldBeFound': 'No {{item}} could be found',
  'alert.noSchedulesFound': 'No Schedules could be found',
  'alert.noZonesToShow': 'There are no Zones available to show',
  'alert.pleaseSelectALocation': 'Please select a location',
  'alert.pleaseSelectFilter': 'Please select at least one location filter',
  'alert.title.addSignal': 'Add Signal',
  'alert.title.error': 'Error',
  'alert.message.locationAddTaskTemplateLocked':
    "This Task's template is locked and cannot be added to this location. To assign this location to this form, edit it from the Templates screen.",
  'alert.message.locationAddIssueTemplateLocked':
    "This Issue's template is locked and cannot be added to this location. To assign this location to this form, edit it from the Templates screen.",
  'alert.message.locationAddAuditTemplateLocked':
    "This Audit's template is locked and cannot be added to this location. To assign this location to this form, edit it from the Templates screen.",
  'alert.message.reinviteUserSentTo': 'Invitation was successfully resent to',
  'alert.message.reinviteUserSentToError': 'Invitation failed to resend to',
  'alert.message.reinviteUserSent': 'Invitation was successfully resent',
  'alert.message.reinviteUserSentError': 'Invitation failed to resend',

  'reinviteUser.statusBanner.message': `This account was invited <1>{{difference}}</1>, please check the email address is correct below.`,
  'reinviteUser.statusBanner.saveToResend':
    'Save changes to resend the invite.',

  'areas.jobNumberTooltip.message':
    'For WinTeam clients, you may reference the related Job Number',
  'areas.jobNumberTooltip.title': 'WinTeam Job Number',

  'audit.targetTooltip.message':
    'Add the number that you want as the performance standard in the Target field. Entering an "85" in the Target field would set the measure standard to 85%.',
  'audit.targetTooltip.title': 'Enter Target',

  'auditPanel.header.audit': 'Audit',
  'auditPanel.header.newAudit': 'New Audit',

  backToSignIn: 'Back to application sign in',

  'button.add': 'Add',
  'button.addAnother': 'Add Another',
  'button.addBuilding': 'Add Building',
  'button.addField': 'Add Field',
  'button.addGeofence': 'Add Geofence',
  'button.addGroup': 'Add Group',
  'button.addOption': 'Add Option',
  'button.addQuestion': 'Add Question',
  'button.addQuote': 'Add Quote',
  'button.addServiceHours': 'Add Service Hours',
  'button.addUser': 'Add User',
  'button.addUsers': 'Add Users',
  'button.addingUsers': 'Adding Users',
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.clear': 'Clear',
  'button.clearFilters': 'Clear Filters',
  'button.completeSignup': 'Complete Signup',
  'button.confirm': 'Confirm',
  'button.continue': 'Continue',
  'button.delete': 'Delete',
  'button.deleteConfirm': 'Are you sure you want to delete {{name}}?',
  'button.deleteConfirm_schedule':
    'Are you sure you want to delete this schedule? Deleting the schedule will remove all active and future instances of this schedule.',
  'button.deleteConfirm_small': 'Confirm delete?',
  'button.deleteConfirm_unnamed': 'Are you sure you want to delete?',
  'button.deviceRel': 'Device Rel',
  'button.discard': 'Discard',
  'button.discardConfirm': 'Are you sure you want to discard your changes?',
  'button.discardConfirm_small': 'Discard changes?',
  'button.duplicate': 'Duplicate',
  'button.edit': 'Edit',
  'button.editBoundaries': 'Edit Boundaries',
  'button.exit': 'Exit',
  'button.fetchingApplication': 'Fetching application',
  'button.filter': 'Filter',
  'button.forgotPassword': 'Forgot Password',
  'button.goLive': 'Go Live',
  'button.hide': 'Hide',
  'button.invite': 'Invite',
  'button.inviteUser': 'Invite User',
  'button.invitingUser': 'Inviting User',
  'button.legend': 'Legend',
  'button.live': 'Live',
  'button.map': 'MAP',
  'button.moveDown': 'Move Down',
  'button.moveUp': 'Move Up',
  'button.newApplication': 'New Application',
  'button.newEntry': 'New Entry',
  'button.newGroup': 'New Group',
  'button.newLoop': 'New Loop',
  'button.newRole': 'New Role',
  'button.newSchedule': 'New Schedule',
  'button.newSignal': 'New Signal',
  'button.newTemplate': 'New Template',
  'button.next': 'Next',
  'button.now': 'Now',
  'button.pdf': 'PDF',
  'button.printCodes': 'Print Codes',
  'button.printLabels': 'Print Labels',
  'button.processing': 'Processing',
  'button.qrCodes': 'QR Codes',
  'button.remove': 'Remove',
  'button.removeFormGroupConfirm': 'Remove form group?',
  'button.resendInvite': 'Resend Invite',
  'button.reset': 'Reset',
  'button.save': 'Save',
  'button.saveConfirm': 'Are you sure you want to save your changes?',
  'button.saveConfirm_schedule':
    'Are you sure you want to update this schedule? Updating the schedule will replace all active and future instances of this schedule.',
  'button.saveConfirm_small': 'Save changes?',
  'button.saveGroup': 'Save Group',
  'button.saving': 'Saving',
  'button.signIn': 'Sign In',
  'button.syncing': 'Syncing',
  'button.today': 'Today',
  'button.update': 'Update',

  'error.applicationNotFound': 'The Application could not be found.',
  'error.duplicateDocument':
    'The data you sent duplicates a document that already exists',

  'inputSelect.selectAll': 'Select {{totalCount}} {{type}}s',
  'inputSelect.showCount': 'Showing {{totalCount}} {{type}}s',
  'inputSelect.removeSelected': 'Remove {{selectedCount}} {{type}}s',

  'issuePanel.header.issue': 'Issue',
  'issuePanel.header.newIssue': 'New Issue',

  label12HourClock: '12 hour clock',
  label24HourClock: '24 hour clock',
  labelAccessPermissions: 'Access Permissions',
  labelActivity: 'Activity',
  labelActivityFeed: 'Activity Feed',
  labelAddress: 'Address',
  labelAllDay: 'All day',
  labelAllLocations: 'All Locations',
  labelAllLocationGroups: 'All Location Groups',
  labelAllTitle: 'All {{title}}',
  labelAlwaysBackground: 'Always (background)',
  labelAnswer: 'Answer',
  labelAppDebugging: 'App Debugging',
  labelArea: 'Area',
  labelAreas: 'Areas',
  labelAreasAndSignals: 'Areas and Signals',
  labelAssignTo: 'Assign to',
  labelAssignedTo: 'Assigned to',
  labelAssignee: 'Assignee',
  labelAssignees: 'Assignees',
  labelAttachments: 'Attachments',
  labelAudit: 'Audit',
  labelAuditGroups: 'Audit Groups',
  labelAuditScoresAverageByLocation: 'Audit scores (average) by location: ',
  labelAuditScoresAverageOverTime: 'Audit scores (average) over time',
  labelAuditTemplates: 'Audit Templates',
  labelAudits: 'Audits',
  labelAuthentication: 'Authentication',
  labelAutomatically: 'Automatically',
  labelAverageExceptionDuration: 'Average Exception Duration: ',
  labelBackInTime: 'Back in Time',
  labelBeacon: 'Beacon',
  labelBeaconMajor: 'Beacon Major',
  labelBeaconMinor: 'Beacon Minor',
  labelBeaconUuid: 'Beacon Uuid',
  labelCheckboxes: 'Checkboxes',
  labelCity: 'City',
  labelCloseTime: 'Close Time',
  labelClosed: 'Closed',
  labelComments: 'Comments',
  labelConfirmNewPassword: 'Confirm New Password',
  labelContent: 'Content',
  labelContentEntries: 'Content Entries',
  labelContentTemplate: 'Content Template',
  labelContentTemplates: 'Content Templates',
  labelConfirmLocation: 'Yes - Confirm Location',
  labelConfirmGeofence: 'Yes - Confirm Geofence',
  labelConfirmLocationAndGeofence: 'Yes - Confirm Location And Geofence',
  labelEndShiftConfirmGeofence:
    'Must be within Start Shift Location, but allow bypass',
  labelEndShiftCopyStartShiftLocation: 'Copy Start Shift Location',
  labelEndShiftNone: 'No Restriction - copy Start Shift Location if unknown',
  labelCountry: 'Country',
  labelCreate: 'Create',
  labelCreateNewGroup: 'Create New Group',
  labelCreated: 'Created',
  labelDayforce: 'Dayforce',
  labelDayforceEmployeeBadgeNumber: 'Employee Badge Number',
  labelDailyLocationReport: 'Daily Location Report',
  labelDailyLocationReports: 'Daily Location Reports',
  labelDailyShiftReport: 'Daily Shift Report',
  labelDashboard: 'Dashboard',
  labelDate: 'Date',
  labelDayforceLocationXRefCode: 'Location XRef Code',
  labelDayDate: 'Day / Date',
  labelDays: 'Day(s)',
  labelDefaultRole: 'Default Role',
  labelDefaultRoleDescription: 'This is the default role for new users.',
  labelDelete: 'Delete',
  labelDescription: 'Description',
  labelDetails: 'Details',
  labelDevices: 'devices',
  labelDisplayAlways: 'Display Always',
  labelDisplayImage: 'Display Image',
  labelDisplayOnFormEntryOnly: 'Display on form entry only',
  labelDisplayText: 'Display Text',
  labelDuration: 'Duration',
  labelDuressAlerts: 'Duress Alerts',
  labelEdit: 'Edit',
  labelEditGroup: 'Edit Group',
  labelEditGroupDetails: 'Edit Group Details',
  labelEmail: 'Email',
  labelEmailOrUsername: 'Email or Username',
  labelEmployee: 'Employee',
  labelEmployeeNumber: 'Employee Number',
  labelEnabled: 'Enabled',
  labelEnd: 'End',
  labelEndDate: 'End Date',
  labelEndLocation: 'End Location',
  labelEndSchedule: 'End Schedule',
  labelEndShift: 'End Shift',
  labelEndTime: 'End Time',
  labelEndShiftRestriction: 'End Shift Restrictions',
  labelEnglishUs: 'English (US)',
  labelEnglishUsShort: 'English (US)',
  labelEnter: 'Enter',
  labelEvent: 'Event',
  labelEventType: 'Event Type',
  labelEvents: 'Events',
  labelExceptionClosed: 'Exception Closed',
  labelExceptionOpened: 'Exception Opened',
  labelExceptions: 'Exceptions',
  labelExit: 'Exit',
  labelExport: 'Export',
  labelExports: 'Exports',
  labelField: 'Field',
  labelFile: 'File',
  labelFirstName: 'First Name',
  labelFloors: 'Floor(s)',
  labelFloorsTitle: 'Floors',
  labelFooterFields: 'Footer Fields',
  labelFormTemplates: 'Form Templates',
  labelFrenchCa: 'French (Canadian)',
  labelFrenchCaShort: 'French (CA)',
  labelFrom: 'From',
  labelGPS: 'GPS',
  labelGroupLabel: 'Group Label',
  labelGroupName: 'Group Name',
  labelGroupScore: 'Group Score',
  labelHeaderFields: 'Header Fields',
  labelHighest: 'Highest',
  labelHours: 'Hour(s)',
  labelID: 'ID',
  labelInactiveRole: 'Inactive Role',
  labelInactiveRoleDescription: 'This is the role intended for inactive users.',
  labelInProgress: 'In Progress',
  labelIncludeUnassignedLocationData: 'Include unassigned location data',
  labelInvited: 'Invited',
  labelIssue: 'Issue',
  labelIssueTemplates: 'Issue Templates',
  labelIssues: 'Issues',
  labelIssuesByLocation: 'Issues by location: ',
  labelIssuesOverTime: 'Issues Over Time',
  labelJobNumber: 'Job Number',
  labelLabel: 'Label',
  labelLastActivity: 'Last Activity',
  labelLastLogin: 'Last Login',
  labelLastName: 'Last Name',
  labelLastUpdated: 'Last Updated',
  labelLocation: 'Location',
  labelLocationCount: 'Location Count',
  labelLocationGroup: 'Location Group',
  labelLocationGroups: 'Location Groups',
  labelLocationSettings: 'Location Settings',
  labelLocationSettingsDescription:
    'Control when to record a users location with the following settings:',
  labelLocations: 'Locations',
  labelLocationsReports: 'Locations Reports',
  labelLocked: 'Locked',
  labelLatitude: 'Latitude',
  labelLongitude: 'Longitude',
  labelLoopExceptions: 'Loop Exceptions',
  labelLoopTimes: 'Loop Times',
  labelLoops: 'Loops',
  labelLowest: 'Lowest',
  labelManually: 'Manually',
  labelMaps: 'Maps',
  labelMedia: 'Media',
  labelMessageGroups: 'Message Groups',
  labelMessages: 'Messages',
  labelMinutes: 'Minutes',
  labelMobile: 'Mobile',
  labelMobileAndWeb: 'Mobile and Web',
  labelMobileOnly: 'Mobile only',
  labelMonths: 'Month(s)',
  labelMultiLine: 'Multi-line?',
  labelMultiSelectList: 'Multi Select List',
  labelName: 'Name',
  labelNewAndUpdatedIssuesAssignedToMe:
    'New and Updated Issues, assigned to me',
  labelNewArea: 'New Area',
  labelNewAudit: 'New Audit',
  labelNewCustomEntry: 'New {{template}} Entry',
  labelNewGroup: 'New Group',
  labelNewIssue: 'New Issues, not assigned to me',
  labelNewLoopTimes: 'New Loop Times',
  labelNewPassword: 'New Password',
  labelNewRole: 'New Role',
  labelNewTask: 'New Task',
  labelNewTemplate: 'New Template',
  labelNewTemplateEntry: 'New Template Entry',
  labelNewUser: 'New User',
  labelNfc: 'NFC',
  labelNo: 'No',
  labelNoEndDate: 'No end date',
  labelNoMessagesYet: 'No messages yet...',
  labelNoRepeat: 'No Repeat',
  labelNotRepeatable: 'Not Repeatable',
  labelNotification: 'Notification',
  labelNotificationPreferences: 'Notification Preferences',
  labelNumber: 'Number',
  labelOff: 'Off',
  labelOn: 'On',
  labelOnline: 'Online',
  labelOffline: 'Offline',
  labelOpen: 'Open',
  labelOpenTime: 'Open Time',
  labelOpened: 'Opened',
  labelOptional: 'optional',
  labelOptions: 'Options',
  labelOverallScore: 'Overall Score:',
  labelParticipants: 'Participants',
  labelPassword: 'Password',
  labelPendingUser: 'Pending User',
  labelPlatformAccess: 'Platform Access',
  labelPostCode: 'Post Code',
  labelProfile: 'Profile',
  labelPush: 'Push',
  labelQrCode: 'QR Code',
  labelQrCodes: 'QR Codes',
  labelQuestion: 'Question',
  labelQuestions: 'Questions',
  labelRadioButtons: 'Radio Buttons',
  labelRaisedBy: 'Raised by',
  labelRecentIssues: 'recent issues',
  labelRecentTasks: 'recent tasks',
  labelReference: 'Reference',
  labelRelationship: 'Relationship',
  labelRepeat: 'Repeat',
  labelRepeatFrequency: 'Repeat Frequency',
  labelRepeatOn: 'Repeat on',
  labelRepeatOnSetTimeIntervals: 'Repeat on set time intervals',
  labelRepeatOnTimeSinceLastActivityCompletion:
    'Repeat on time since last activity completion',
  labelRepeatable: 'Repeatable',
  labelReportsDashboard: 'Reports Dashboard',
  labelRequired: 'Required?',
  labelRequiredField: 'required',
  labelRestrictIssueClosing: 'Restrict Issue Closing',
  labelRole: 'Role',
  labelRoles: 'Roles',
  labelRolesAndPermissions: 'Roles and Permissions',
  labelSMS: 'SMS',
  labelSchedule: 'Schedule',
  labelScheduleWork: 'Schedule Work',
  labelScheduledWork: 'Scheduled Work',
  labelScore: 'Score',
  labelSection: 'Section',
  labelSelect: 'Select',
  labelSendAfter: 'Send After',
  labelServiceHours: 'Service Hours',
  labelServiceLevel: 'Service Level',
  labelSetup: 'Setup',
  labelShare: 'Share Form PDF',
  labelShiftFinished: 'Shift Finished',
  labelShiftStarted: 'Shift Started',
  labelShiftStatus: 'Status',
  labelShifts: 'Shifts',
  labelSignal: 'Signal',
  labelSignals: 'Signals',
  labelSignature: 'Signature',
  labelSingleSelectList: 'Select List',
  labelSkipLocationsWithNoActivity: 'Skip Locations with no Activity',
  labelSpanishLa: 'Spanish (Latin-America)',
  labelSpanishLaShort: 'Spanish (LA)',
  labelSpecificActions: 'Specific Actions',
  labelStarRating: 'Star Rating',
  labelStart: 'Start',
  labelStartEndTime: 'Start and end time',
  labelStartLocation: 'Start Location',
  labelStartShiftRestriction: 'Start Shift Restrictions',
  labelStartSchedule: 'Start Schedule',
  labelStartShift: 'Start Shift',
  labelStartTime: 'Start Time',
  labelState: 'State',
  labelStatus: 'Status',
  labelStreet: 'Street',
  labelSummaryField: 'Summary Field',
  labelSummaryReport: 'Summary Report',
  labelSummaryReportFormSubmissions: 'Summary Report + Form Submissions',
  labelSwitch: 'Switch',
  labelTarget: 'Target',
  labelTargetParcentage: 'Target (%)',
  labelAboveTarget: 'Above Target',
  labelBelowTarget: 'Below Target',
  labelNoTarget: 'No Target',
  labelNoAccess: 'No Access',
  labelOnTarget: 'On Target',
  labelTask: 'Task',
  labelTaskTemplates: 'Task Templates',
  labelTasks: 'Tasks',
  labelTemplate: 'Template',
  labelTemplates: 'Templates',
  labelText: 'Text',
  labelTime: 'Time',
  labelTimegate: 'Timegate',
  labelTempla: 'Templa',
  labelTimegateSIN: 'SIN',
  labelTimegateEmployeePIN: 'Timegate PIN',
  labelTimeMins: 'Time (mins)',
  labelTimeline: 'Timeline',
  labelTimestamp: 'Timestamp',
  labelTimezone: 'Timezone',
  labelTitle: 'Title',
  labelTitleDateRange: '{{title}} from {{from}} to {{to}}',
  labelTo: 'To',
  labelType: 'Type',
  labelUnknownLocation: 'Unknown Location',
  labelUnlimited: 'Unlimited',
  labelUpdatedAudit: 'Updated Audit',
  labelUpdatedIssue: 'Updated Issues, not assigned to me',
  labelUpdatedTask: 'Updated Task',
  labelUnknownArea: 'Unknown Area',
  labelUnknownLocationGroup: 'Unknown Location Group',
  labelUnknownSignal: 'Unknown Signal',
  labelUseLocationServiceHours: 'Use location service hours',
  labelUser: 'User',
  labelUserRoles: 'User Roles',
  labelUserCanSkip: 'User can skip',
  labelUserId: 'User Id',
  labelUsername: 'Username',
  labelUsers: 'Users',
  labelView: 'View',
  labelVisit: 'Visit',
  labelVisits: 'Visits',
  labelWebOnly: 'Web only',
  labelWeeks: 'Week(s)',
  labelWeight: 'Weight',
  labelWinTeam: 'WinTeam',
  labelWinTeamEnabled: 'WinTeam Enabled',
  labelXLocations: '{{ count }} Locations',
  labelXLocationGroups: '{{ count }} Locations Groups',
  labelYears: 'Year(s)',
  labelYes: 'Yes',
  labelZeroLocations: '0 Locations',
  labelZone: 'Zone',

  'leftPanel.locationGroupView.title':
    ' Select new Location Group to assign to location:',

  'locationGroup.error.addLocations':
    '{{failedCount}} locations could not be added',
  'locationGroup.error.location': 'Location Error',
  'locationGroup.error.locationDeleted': 'This location has been deleted',
  'locationGroup.error.locationDeletedTitle': 'Location Deleted',
  'locationGroup.error.locationAtMaxGroups':
    'This location cannot be added to more groups',
  'locationGroup.error.locationAtMaxGroupsTitle': 'Location Limit',
  'locationGroup.error.groupAtMaxLocations':
    'This group cannot hold any more locations',
  'locationGroup.error.groupAtMaxLocationsTitle': 'Group Limit',

  'locationGroup.crud.tooltip.message':
    'Location Group changes will apply to future form submissions only. For example, tasks submitted at the same location will only show after the location is added to the Location Group.',
  'locationGroup.crud.tooltip.title': 'Saving Location Groups',

  'locationPanel.filter.allLocations': 'All Locations',
  'locationPanel.filter.closed': 'Closed',
  'locationPanel.filter.open': 'Open',
  'locationPanel.filter.openActive': 'Open / Active',
  'locationPanel.filter.openInactive': 'Open / Inactive',
  'locationPanel.sort.locationName': 'Location Name',
  'locationPanel.sort.userCount': 'User Count',
  'locationPanel.status.closed': 'CLOSED',
  'locationPanel.status.open': 'OPEN',
  'locationPanel.userCount': '{{count}} user online',
  'locationPanel.userCount_plural': '{{count}} users online',
  'location.serviceHoursTooltip.message': `Setting these up is helpful for monitoring purposes, as it determines when activity is expected at this location (e.g. for schedules).`,
  'location.serviceHoursTooltip.title': `Service Hours`,

  'login.applicationIdTooltip.message':
    'Your application id is your unique application identifier. It is a single lower case word without spaces (e.g. "company-name"). If you cannot remember your application id, contact support@lighthouse.io.',
  'login.applicationIdTooltip.title': 'Application ID',
  'login.error.noEmailAddress': 'Please enter your Email Address',
  'login.footerMessage': 'New to Lighthouse.io?',
  'login.header.applicationSignIn': 'Sign in to your application',
  'login.header.forgotPassword': 'Reset your Password',
  'login.header.signIn': 'Sign In',
  'login.requestAccount': 'Request account',
  'login.success.resetPassword':
    'Your password has been reset. Follow the instructions in the email we have sent to you at {{username}}',
  'login.whatIsMyApplicationId': 'What is my application id?',
  'logout.header.loggingOut': 'Logging out',

  'map.tooltip.clickToPlace': 'Click to place on map',
  'map.tooltip.invalidText': 'Continue drawing',
  'map.tooltip.new.auditentries': 'New Audit (Click to reposition)',
  'map.tooltip.new.issue': 'New Issue (Click to reposition)',
  'map.tooltip.new.signal': 'New Signal (Click to reposition)',
  'map.tooltip.new.taskentries': 'New Task (Click to reposition)',
  'map.tooltip.outsideLocation': 'Must be inside parent boundary',
  'map.tooltip.startText': 'Click map to start drawing',
  'map.tooltip.validText': 'Click first point to finish',
  'mapOptions.legend': 'Legend',
  'mapOptions.legendItem.active': 'Active (<5m)',
  'mapOptions.legendItem.activeUserCount': 'Active user count',
  'mapOptions.legendItem.audit': 'Audit',
  'mapOptions.legendItem.clusterWithLocation': 'Cluster with location',
  'mapOptions.legendItem.clusterWithoutLocation': 'Cluster without location',
  'mapOptions.legendItem.inactive': 'Inactive (>1h)',
  'mapOptions.legendItem.issue': 'Issue',
  'mapOptions.legendItem.recent': 'Recent (1h)',
  'mapOptions.legendItem.task': 'Task',
  'mapOptions.legendItem.userInVehicle': 'User in vehicle',
  'mapOptions.newAudit': 'New Audit',
  'mapOptions.newIssue': 'New Issue',
  'mapOptions.newLocation': 'New Location',
  'mapOptions.newTask': 'New Task',
  'mapOptions.options': 'Options',
  'mapOptions.optionsItem.audits': 'Audits',
  'mapOptions.optionsItem.geofences': 'Geofences',
  'mapOptions.optionsItem.issues': 'Issues',
  'mapOptions.optionsItem.locations': 'Locations',
  'mapOptions.optionsItem.map': 'Map',
  'mapOptions.optionsItem.people': 'People',
  'mapOptions.optionsItem.satellite': 'Satellite',
  'mapOptions.optionsItem.signals': 'Signals',
  'mapOptions.optionsItem.tasks': 'Tasks',
  'mapOptions.optionsItem.tooltips': 'Tooltips',
  'mapOptions.optionsItem.beacon': 'Beacons',
  'mapOptions.optionsItem.qrcode': 'QR',
  'mapOptions.optionsItem.nfc': 'NFC',

  'modal.delete.area.title': 'Delete this area?',
  'modal.delete.area.message':
    'Are you sure you want to delete this area?\n\nDeleting this floor will delete all areas and signals contained within this area. This action cannot be reversed.',
  'modal.delete.audit.title': 'Delete this audit?',
  'modal.delete.audit.message':
    'Are you sure you want to delete this audit?\n\nDeleting the audit removes the submission. This action cannot be reversed.',
  'modal.delete.building.title': 'Delete this building?',
  'modal.delete.building.message':
    'Are you sure you want to delete this building?\n\nDeleting this building will delete all areas, floors and signals contained within this building. This action cannot be reversed.',
  'modal.delete.floor.title': 'Delete this floor?',
  'modal.delete.floor.message':
    'Are you sure you want to delete this floor?\n\nDeleting this floor will delete all areas and signals contained within this floor. This action cannot be reversed.',
  'modal.delete.issue.title': 'Delete this issue?',
  'modal.delete.issue.message':
    'Are you sure you want to delete this issue?\n\nDeleting the issue removes the submission. This action cannot be reversed.',
  'modal.delete.location.title': 'Delete this location?',
  'modal.delete.location.message':
    'Are you sure you want to delete this location?\n\nDeleting this location will delete all areas and signals contained within this location. This action cannot be reversed.',
  'modal.delete.locationGroup.title': 'Delete this location group?',
  'modal.delete.locationGroup.message':
    'Are you sure you want to delete this location group?\n\nDeleting the location group removes the ability to filter templates, filter reports, and assign the group to templates. It does not delete the locations or remove them from other location groups.',
  'modal.delete.messageGroup.title': 'Delete this message group?',
  'modal.delete.messageGroup.message':
    'Are you sure you want to delete this message group?',
  'modal.delete.role.title': 'Delete this role?',
  'modal.delete.role.message':
    'Are you sure you want to delete this role?\n\nRoles cannot be deleted while active users are assigned to it. Deleting the role removes the ability to filter Reports by this role. Deleting the role does not delete previously submitted data.',
  'modal.delete.schedule.title': 'Delete this schedule?',
  'modal.delete.schedule.message':
    'Are you sure you want to delete this schedule?\n\nDeleting the schedule will remove all active and future instances of this schedule.',
  'modal.delete.signal.title': 'Delete this signal?',
  'modal.delete.signal.message':
    'Are you sure you want to delete this signal?\n\nDeleting this signal will delete associated NFC Tag ID or QR Codes (as appropriate). QR Codes cannot be reused once deleted. NFC Tags can be reassigned to new signals as required.',
  'modal.delete.signals.title': 'Delete these signals?',
  'modal.delete.signals.message':
    'Are you sure you want to delete these signals?\n\nDeleting these signals will delete associated NFC Tag ID or QR Codes (as appropriate). QR Codes cannot be reused once deleted. NFC Tags can be reassigned to new signals as required.',
  'modal.delete.task.title': 'Delete this task?',
  'modal.delete.task.message':
    'Are you sure you want to delete this task?\n\nDeleting the task removes the submission. This action cannot be reversed.',
  'modal.delete.template.title': 'Delete this template?',
  'modal.delete.template.message':
    'Are you sure you want to delete this template?\n\nDeleting the template removes the ability to filter Reports by this template. Deleting the template does not delete previously submitted data.',
  'modal.delete.user.title': 'Delete this user?',
  'modal.delete.user.message':
    'Are you sure you want to delete this user?\n\nDeleting the user removes the ability to filter Reports by this user. Deleting the user does not delete previously submitted data.',

  'navigation.admin': 'Admin',
  'navigation.admin.applications': 'Applications',
  'navigation.logout': 'Logout',
  'navigation.maps': 'Maps',
  'navigation.messages': 'Messages',
  'navigation.reports': 'Reports',
  'navigation.reports.audits': 'Audits',
  'navigation.reports.dashboard': 'Dashboard',
  'navigation.reports.events': 'Events',
  'navigation.reports.exceptions': 'Exceptions',
  'navigation.reports.exports': 'Exports',
  'navigation.reports.issues': 'Issues',
  'navigation.reports.locations': 'Locations',
  'navigation.reports.shifts': 'Shifts',
  'navigation.reports.tasks': 'Tasks',
  'navigation.schedules': 'Schedules',
  'navigation.schedules.work': 'Work',
  'navigation.settings': 'Settings',
  'navigation.settings.profile': 'Profile',
  'navigation.settings.roles': 'Roles & Permissions',
  'navigation.settings.users': 'Users',
  'navigation.setup': 'Setup',
  'navigation.setup.locations': 'Locations',
  'navigation.setup.location-groups': 'Location Groups',
  'navigation.setup.loops': 'Loop Times',
  'navigation.setup.signals': 'Signals',
  'navigation.templates': 'Templates',
  'navigation.templates.audits': 'Audits',
  'navigation.templates.content': 'Content Templates',
  'navigation.templates.entries': 'Content Entries',
  'navigation.templates.issues': 'Issues',
  'navigation.templates.tasks': 'Tasks',

  'panel.header.loadingTemplates': 'Loading Templates',
  'panel.header.selectTemplate': 'Select Template',

  'placeholder.NfcTagId': 'NFC Tag ID',
  'placeholder.advertisingInterval': 'Advertising Interval',
  'placeholder.allLocations': 'All Locations',
  'placeholder.allZones': 'All Zones',
  'placeholder.auditTitle': 'Audit Title',
  'placeholder.beaconMajor': 'Beacon Major',
  'placeholder.beaconMinor': 'Beacon Minor',
  'placeholder.chooseAGroupName': 'Choose a Group Name',
  'placeholder.chooseOptionOrAddNewOption': 'Choose option or add new option',
  'placeholder.city': 'City',
  'placeholder.description': 'Description',
  'placeholder.email': 'email',
  'placeholder.enterApplicationId': 'enter application id',
  'placeholder.enterMessage': 'Enter message...',
  'placeholder.enterMobileNumber': 'Enter Mobile Number',
  'placeholder.firstName': 'first name',
  'placeholder.floors': 'Floors',
  'placeholder.issueTitle': 'Issue Title',
  'placeholder.label': 'Label',
  'placeholder.lastName': 'last name',
  'placeholder.latitude': 'Latitude',
  'placeholder.longitude': 'Longitude',
  'placeholder.mins': 'Mins',
  'placeholder.mobile': 'mobile',
  'placeholder.name': 'Name',
  'placeholder.nameOfLocationGroup': 'Name of Location Group',
  'placeholder.password': 'password',
  'placeholder.postCode': 'Post code',
  'placeholder.reference': 'Reference',
  'placeholder.roleName': 'Role Name',
  'placeholder.rssi': 'RSSI',
  'placeholder.search': 'Search',
  'placeholder.searchLocations': 'Search locations',
  'placeholder.searchOrSelect': 'Search or Select',
  'placeholder.search_ellipsis': 'Search...',
  'placeholder.select': 'Select',
  'placeholder.selectANotificationMethod': 'Select a Notification Method',
  'placeholder.selectAccess': 'Select Access',
  'placeholder.selectAnOption': 'Select an option',
  'placeholder.selectArea': 'Select Area',
  'placeholder.selectAreas': 'Select Areas',
  'placeholder.selectAssignedUsers': 'Select Assigned Users',
  'placeholder.selectAuditTemplate': 'Select Audit Template',
  'placeholder.selectCountry': 'Select Country',
  'placeholder.selectDateFormat': 'Select Date Format',
  'placeholder.selectDates': 'Select Dates',
  'placeholder.selectFilterCollection': 'Select Filter Collection',
  'placeholder.selectFilters': 'Select Filters',
  'placeholder.selectIssueStatus': 'Select Issue Status',
  'placeholder.selectIssueTemplate': 'Select Issue Template',
  'placeholder.selectLanguage': 'Select Language',
  'placeholder.selectLocation': 'Select Location',
  'placeholder.selectLocationGroup': 'Select Locations Group',
  'placeholder.selectLocations': 'Select Locations',
  'placeholder.selectLocationsLocationGroups': 'Select Location (max. 1000)',
  'placeholder.selectOption': 'Select option',
  'placeholder.selectOrDropPin': 'Select or drop pin',
  'placeholder.selectOrSearch': 'Select or Search',
  'placeholder.selectParticipants': 'Select Participants',
  'placeholder.selectRole': 'Select Role',
  'placeholder.selectRoles': 'Select Roles',
  'placeholder.selectShiftType': 'Select Shift Type',
  'placeholder.selectTaskTemplate': 'Select Task Template',
  'placeholder.selectTemplate': 'Select Template',
  'placeholder.selectTime': 'Select time',
  'placeholder.selectTimeFormat': 'Select Time Format',
  'placeholder.selectTimezone': 'Select Timezone',
  'placeholder.selectType': 'Select Type',
  'placeholder.selectUser': 'Select User',
  'placeholder.selectUserRoles': 'Select User Roles',
  'placeholder.selectUsers': 'Select Users',
  'placeholder.signalLabel': 'Signal Label',
  'placeholder.signalType': 'Signal Type',
  'placeholder.state': 'State',
  'placeholder.streetAddress': 'Street Address',
  'placeholder.target': 'Target',
  'placeholder.taskTitle': 'Task Title',
  'placeholder.timegateEmployeePIN': 'PIN',
  'placeholder.transmittingPower': 'Transmitting Power',
  'placeholder.username': 'username',
  'placeholder.uuid': 'UUID',
  'placeholder.winTeam': 'WinTeam',

  privacy: 'Privacy',

  'prompts.error.applicationError': 'Application error. Please contact support',
  'prompts.error.fieldToComplete': 'You have {{count}} field to complete',
  'prompts.error.fieldToComplete_plural':
    'You have {{count}} fields to complete',
  'prompts.error.pleaseCompleteAllFields': 'Please complete all fields',
  'prompts.error.validation': 'You have {{count}} validation error',
  'prompts.error.validation_plural': 'You have {{count}} validation errors',
  'prompts.unsaved.youHaveUnsavedChanges': 'You have unsaved changes',
  'prompts.warning.resolveWarnings': 'Please resolve these warnings',

  'roles.adhocTooltip.message':
    'Record location when users take specific actions using the mobile app. Actions include start/end shift, qr code scan, submit task, submit issue, and submit audit.',
  'roles.adhocTooltip.title': 'Record location on actions',
  'roles.passiveTooltip.message':
    'Record location continuously in the background, even if the mobile app is closed',
  'roles.passiveTooltip.title': 'Always record location',

  'schedules.locationsTooltip.title': 'Select Locations',
  'schedules.locationsTooltip.message': `Assign to the appropriate location(s). If you select multiple locations you cannot also assign to Signals and Areas.`,
  'schedules.areasTooltip.title': 'Select Area(s) and signal(s)',
  'schedules.areasTooltip.message': `Assign to the appropriate Area(s) and Signal(s).`,
  'schedules.enableTooltip.title': 'Schedule Enable option',
  'schedules.enableTooltip.message': `Enabled drop down to Yes. If this field is set to No, then this scheduled work item will not be active.`,
  'schedules.usersTooltip.title': 'Select User(s)',
  'schedules.usersTooltip.message': `Assign to the appropriate Users. If the schedule is assigned to multiple users, it only needs to be completed by one of those users.`,
  'schedules.repeatTooltip.title': 'Select Repeat option',
  'schedules.repeatTooltip.message':
    'The <i>No Repeat</i> option is a one-time occurrence and defaults to use the location service hours.<br/>The <i>Repeat on time since last activity completion</i> option bases the start timer on the time of the activity was completed.<br/>The <i>Repeat on set time intervals</i> option bases the start time on regular intervals starting from the location’s open service hour time.',
  'schedules.rolesTooltip.title': 'Select Role(s)',
  'schedules.rolesTooltip.message': `Assign to the appropriate Roles. If the schedule is assigned to multiple roles, it only needs to be completed by one of those users.`,
  'schedules.typeTooltip.title': 'Schedule Type',
  'schedules.typeTooltip.message': `Select the Type drop down and select either Visit, Audit, or Task. Visit requires a location event to complete, while Audit/Task requires submission of selected Audit/Task.`,
  'schedule.startTooltip.message': `Set the Start Schedule date, based on when you want this item to start. Note selecting Today will start the schedule exactly at the time submitted (e.g. 1PM if save at 1PM). If you want schedules to commence at the start of the day, select a future date (e.g. tomorrow).
  If there are specific hours for this work to be completed, clear the Use location service hours check box, click the Add Service Hours button and set up the Day/Date, Options, Open Time and Close Time options.`,
  'schedule.startTooltip.title': 'Start Schedule option',

  'searchMenu.footerMessage': 'Search and select by location name or address',
  'searchMenu.header.recent': 'Recent',
  'searchMenu.noRecentLocations': 'No recent locations',
  'searchMenu.noResultsFound': 'No locations found',
  'searchPanel.noSearch': 'Enter search text to return results',
  'searchPanel.userLastSeen': 'Last Seen - {{datetime}}',

  'setup.bulkSelect': 'Click to select all signals shown on the page.',
  'setup.clearSelection': 'Clear selection.',
  'setup.selectMessage': '{{selectedCount}} of {{totalCount}} selected.',

  switchApplication: 'Switch application',

  'taskPanel.header.newTask': 'New Task',
  'taskPanel.header.task': 'Task',

  'template.fieldReference.message':
    'A value to use as a reference for exports or link to an external system.',
  'template.fieldReference.title': 'Reference',
  'template.fieldTooltip.message':
    'A field represents a single input in your form.',
  'template.fieldTooltip.title': 'Field',
  'template.groupNameTooltip.message': `A group is an organizing mechanism for grouping sets of form fields.`,
  'template.groupNameTooltip.title': 'Group Name',
  'template.locationGroup.title': 'Assigning Location Groups',
  'template.locationGroup.message': `Location Group assignments will apply to future form submissions only. For example, tasks submitted at the same location will only show under a Location Group after the template is added.`,
  'template.repeatTooltip.message':
    'When the repeatable group option is enabled on a form template it allows the employee filling out the form to repeat the form group more than once.',
  'template.repeatTooltip.title': 'Select Repeatable',
  'template.requiredTooltip.message': `Yes - Question requires response. Form will not submit with unanswered questions
  
  No - Users can skip question. Skipped questions will not impact audit score`,
  'template.requiredTooltip.title': 'Required',
  'template.scoreTooltip.message': `The score value represents the result of the question in its own context.
  Think of it as a percentage score on a scale of 0-1, e.g. Pass = 1, OK = 0.5
  and Fail = 0.`,
  'template.scoreTooltip.title': 'Score',
  'template.summaryFieldTooltip.message':
    'Summary fields allow you to display additional information in activity summaries. You can select up to {{MAX_SUMMARY_FIELDS}} summary fields per template.',
  'template.summaryFieldTooltip.title': 'Summary Field',
  'template.typeTooltip.message': `Allows you to specify the input type to be displayed. Text allows simple
  text input, Select allows multiple pre-defined input options, Switch allows
  for a check box input, Media allows media upload.`,
  'template.typeTooltip.title': 'Type',
  'template.weightTooltip.message': `The weight value represents the weighting of this question's score in
  relation to other questions. It should be a positive numerical value.
  If the question matters more than others, give it a higher value. We
  multiply the value with the score for the question when the audit is
  submitted to get an overall total.`,
  'template.weightTooltip.title': 'Weight',
  'template.statusBanner.locked':
    'This template is currently locked and not editable by anyone',
  'template.statusBanner.unlocked':
    'This template is currently unlocked and editable by anyone',
  'template.statusBanner.lockButtonLock': 'Lock',
  'template.statusBanner.lockButtonUnlock': 'Unlock',

  terms: 'Terms',

  textAbilityToCreateMessageGroups: 'Ability to create message groups',
  textAbilityToGenerateDownloadDataExports:
    'Ability to generate and download data exports',
  textAbilityToManageFormTemplates: 'Ability to view and manage Form Templates',
  textAbilityToManageLoopTimes: `Ability to view and manage loop times.  Access to individual
    loop times are defined at a user level`,
  textAbilityToRestrictIssueClosing:
    'Prevent the ability to change issue status to "closed"',
  textAbilityToSetupPages: 'Ability to view Setup and associated pages',
  textAbilityToShareForms: 'Ability to share forms from mobile',
  textAbilityToViewApplicationRoles:
    'Ability to view application roles and add/edit/delete roles & permissions',
  textAbilityToViewApplicationUser:
    'Ability to view application user and add/edit/delete users',
  textAbilityToViewCreateEditLocations: `Ability to view, create, and edit locations. Access to individual
    locations are assigned at a user level.`,
  textAbilityToViewCreateEditSignals:
    'Ability to view, create and edit Signals, including signal assignment from the mobile app',
  textAbilityToViewCreateEditWork:
    'Ability to view, create and edit scheduled work',
  textAbilityToViewDailyLocationReports:
    'Ability to view the Daily location reports',
  textAbilityToViewExceptionsTriggered: 'Ability to view exceptions triggered',
  textAbilityToViewManage: 'Ability to view and manage',
  textAbilityToViewManageEvents: 'Ability to view and manage Events',
  textAbilityToViewMapTimeline:
    'Ability to view map timeline and historic location data',
  textAbilityToViewMaps: 'Ability to view Maps',
  textAbilityToViewRecentActivity: 'Ability to view the recent activity feed',
  textAbilityToViewReportsDashboard: 'Ability to view the reports dashboard',
  textAbilityToViewSendMessages: 'Ability to view and send messages',
  textAbilityToViewSignalTesting: 'Ability to view signal testing',
  textShiftsPermissionDescription:
    'Ability to view submitted shifts on web and create shifts on mobile',
  textAllAudits: '...all audits',
  textAllIssues: '...all issues',
  textAllTasks: '...all tasks',
  textAssignedTo: '/assigned to',
  textCreateYourFirstGroup: 'Create your first group',
  textDoNotBelongToAnyMessageGroups:
    'You do not currently belong to any message groups.',
  textLoading: 'Loading...',
  textNoResults: 'No results',
  textNow: 'now',
  textOnlyAudits: '...only audits created by{{assigneesDescription}} this user',
  textOnlyIssues: '...only issues created by{{assigneesDescription}} this user',
  textOnlyTasks: '...only tasks created by{{assigneesDescription}} this user',
  textResendAllInvites: 'Resend Invites',
  textResendAllInvitesConfirm:
    'This will resend invitations to all users who currently have pending invitations. Are you sure you want to resend invites to {{invitedUsersCount}} users?',
  textResendAllInvitesConfirmEnd: 'users?',
  textResendAllInvitesSuccess:
    'Invites are being resent. This may take a few minutes to complete.',
  textResendAllInvitesError:
    'A problem occurred with resending invites. Please try again.',
  textResendAllInvitesCooldownHoursAndMinutes:
    'Resending invitations to all users with a pending invitation may be done once every 8 hours. This action can be repeated in {{hoursRemaining}} hours and {{minutesRemaining}} minutes.',
  textResendAllInvitesCooldownMinutes:
    'Resending invitations to all users with a pending invitation may be done once every 8 hours. This action can be repeated in {{minutesRemaining}} minutes.',
  textUnknown: 'Unknown',

  titleAddUsers: 'Add Users',
  titleGeoSettings: 'Geo Settings',
  titleNewSignal: 'New Signal',
  titleNewUsers: 'New Users',

  'tooltip.message.Uuid':
    'A UUID Spec v4 string used to differentiate a large group of related beacons.',
  'tooltip.message.advertisingInterval':
    'The delay between broadcast advertising packets. This should be a positive integer.',
  'tooltip.message.beaconMajor':
    'Used to group a related set of beacons (e.g. all beacons at a single location).',
  'tooltip.message.beaconMajorAlt':
    'A positive integer used to differentiate a smaller subset of beacons within the larger group.',
  'tooltip.message.beaconMinor':
    'Used to identify individual beacons (e.g. a single zone at a single location).',
  'tooltip.message.beaconMinorAlt':
    'A positive integer that is used to identify individual beacons.',
  'tooltip.message.beaconUuid':
    'Unique beacon identifier that helps the mobile application distinguish your beacons from other beacons. You should use the same UUID for all beacons associated with a particular application.',
  'tooltip.message.document': 'Supported File Types, PDF, DOC, DOCX',
  'tooltip.message.jobNumber':
    'For WinTeam clients, you may reference the related Job Number',
  'tooltip.message.locationGroupsRestrictions':
    'Select anywhere from 0 to {{locationsPerGroupMax}} locations for each group. Individual locations can be included in a maximum of {{groupsPerLocationMax}} groups.',
  'tooltip.message.locationXRefCode':
    'For Dayforce clients, you may reference the related Location Code',
  'tooltip.message.mapZoomLevel':
    'Represents the default zoom on the map or floor plan. A standard map zoom would be 17 or higher.',
  'tooltip.message.media': 'Supported File Types, PNG, JPG, JPEG, MP4',
  'tooltip.message.nfcTagId':
    'All NFC tags are manufactured with a unique tag identifier. These are typically 14 hexadecimal characters (e.g. 04 9C 64 D2 45 2B 80) and cannot be modified. These tag IDs are assigned to the signal to identify the tag when scanned by the Lighthouse Mobile App.',
  'tooltip.message.photo': 'Supported File Types, PNG, JPG, JPEG',
  'tooltip.message.shiftGeofenceRestriction': `Confirm Location - will require selecting a location before a shift can be started. The End Shift Location will inherit this location.

    Confirm Geofence - will require users to be within the geofence of a location when starting a shift.
    
    If both are enabled, their GPS must be within the geofence of the selected location on shift start.`,
  'tooltip.message.signalStrengthIndicator':
    "An numeric value indicating the strength of the beacon's signal as seen on the receiving device.",
  'tooltip.message.timezone':
    'The Timezone is the local timezone for the selected location.',
  'tooltip.message.transmittingPower':
    "The power with which the signal leaves the beacon's antenna. Usually between -30 dBm to +4 dBm",
  'tooltip.reference.message':
    'Reference fields are free text fields, typically used to support integrations with external solutions. They do not appear within the mobile app, reports or exports.',
  'tooltip.message.confirmLocation':
    'This setting will require mobile users to confirm their location when starting a shift. For automatic shift starts, they must confirm a location to log in. For manual shift starts, they must select a location to start the shift.',
  'tooltip.message.timegateSIN':
    'For Timegate clients, you may reference the related Site using the Timegate Site Identification Number',
  'tooltip.message.templaSiteCode':
    'For Templa clients, you may reference the related Site using the Templa Site Code',
  'tooltip.title.confirmLocation': 'Location on Shift Start',
  'tooltip.reference.title': 'Reference Field',
  'tooltip.title.Uuid': 'UUID',
  'tooltip.title.advertisingInterval': 'Advertising Interval',
  'tooltip.title.apiError': 'API Error',
  'tooltip.title.beaconMajor': 'Beacon Major',
  'tooltip.title.beaconMinor': 'Beacon Minor',
  'tooltip.title.beaconUuid': 'Beacon UUID',
  'tooltip.title.document': 'File Upload',
  'tooltip.title.jobNumber': 'WinTeam Job Number',
  'tooltip.title.locationGroupsRestrictions': 'Location Group Restrictions',
  'tooltip.title.locationXRefCode': 'Dayforce Location Code',
  'tooltip.title.mapZoomLevel': 'Map Zoom Level',
  'tooltip.title.media': 'Media Upload',
  'tooltip.title.nfcTagId': 'NFC Tag ID',
  'tooltip.title.photo': 'Photo Upload',
  'tooltip.title.shiftGeofenceRestriction':
    'Add Restrictions to Starting a Shift',
  'tooltip.title.signalStrengthIndicator': 'Received Signal Strength Indicator',
  'tooltip.title.timezone': 'Timezone',
  'tooltip.title.transmittingPower': 'Transmitting Power',
  'tooltip.title.validationError': 'Validation Error',
  'tooltip.title.timegateSIN': 'Site Identification Number (SIN)',
  'tooltip.title.templaSiteCode': 'Site Code',
  'tooltip.title.endShiftRestriction': 'End Shift Restrictions',
  'tooltip.message.endShiftRestriction':
    "Either allow any location, restrict to the Start Shift Location or always use the Start Shift Location to end shifts. All options will record the user's raw GPS and whether they were inside the Start Shift Location geofence.",

  'users.authStrategyTooltip.message': `If this feature is available for your organisation, please choose the preferred user authentication provider.`,
  'users.authStrategyTooltip.title': 'Authentication Provider',
  'users.dayforce.message':
    'For Dayforce clients, you may reference the related Employee Badge Number',
  'users.dayforce.employeeBadgeNumber': 'Employee Badge Number',
  'users.endShiftTooltip.message': `If you set End Shift to 'Automatically' the user will end a new shift as soon as
  they logout out of the Lighthouse mobile application. This removes the need to manually
  end shifts on the device.`,
  'users.endShiftTooltip.title': 'End Shift',
  'users.locationsTooltip.message': `Add locations to provide access only to specific sites. Not specifying a location or location group will
  provide default access to all.`,
  'users.locationsTooltip.title': 'Select Locations',
  'users.locationGroupsTooltip.message': `Add location groups to provide access to all locations within the group. Not specifying a location or location group will provide default access to all`,
  'users.locationGroupsTooltip.title': 'Select Location Groups',
  'users.roleTooltip.message': `Select the role type of the user and this will apply associated read and write permissions.
  You can setup and manage roles under the 'Roles & Permissions' tab.`,
  'users.roleTooltip.title': 'Role',
  'users.startShiftTooltip.message': `If you set Start Shift to 'Automatically' the user will start a new shift as soon as
  they login to the Lighthouse mobile application. This removes the need to manually
  start shifts on the device.`,
  'users.startShiftTooltip.title': 'Start Shift',
  'users.timegateEmployeePINTooltip.message':
    'For Timegate clients, you may reference the related Employee PIN.',
  'users.timegateEmployeePINTooltip.title': 'Timegate Employee PIN',
  'users.winTeamEnabledTooltip.message':
    'For WinTeam clients, you may enable or disable WinTeam integration for this user.',
  'users.winTeamEnabledTooltip.title': 'WinTeam Enabled',
  'users.winteamEmployeeNumberTooltip.message':
    'For WinTeam clients, you may reference the related Employee Number.',
  'users.winteamEmployeeNumberTooltip.title': 'WinTeam Employee Number',
  'users.locked.message':
    'This account is locked. Please enter a password to reset the account.',

  'validation.maxLength': 'Must be less than {{maxLength}} characters',
  'validation.numberOrDecimal':
    'Target must be a positive number up to 2 decimal places',
  'validation.requiredField': 'This is a required field',
  'validation.targetMaxError': 'Target must be less than or equal to 100',
  'validation.targetMinError': 'Target must be greater than or equal to 0',
  'validation.wholeNumber': 'Target must be a positive whole number',

  'work.errors.endAtTimeIsRequired': 'End at time is a required field',
  'work.errors.endDateAfterStartDate':
    'End date must occur on or after the start date',
  'work.errors.endTimeAfterStartTime':
    'End time must occur after the start time',
  'work.errors.repeatMinutesNumber': 'Repeat minutes must be a number',
  'work.errors.repeatMinutesOutsideBounds':
    'Repeat minutes must be between 5 and 1440 minutes',
  'work.errors.repeatMinutesWholeNumber':
    'Repeat minutes must be a whole number',
  'work.errors.scheduleEndIsRequired': 'Schedule end at is a required field',
  'work.errors.scheduleStartIsRequired':
    'Schedule start at is a required field',
  'work.errors.startAtTimeIsRequired': 'Start at time is a required field',
  'work.errors.weekdayOptionSelected':
    'At least one weekday options must be selected',
  'work.header.newSchedule': 'New Schedule',
  'work.header.scheduledWork': 'Scheduled Work',
  'work.warning.notRepeating28th':
    "Schedules set for 29th won't repeat on months ending on the 28th",
  'work.warning.notRepeating29th':
    "Schedules set for 30th won't repeat on months ending on or before the 29th",
  'work.warning.notRepeating30th':
    "Schedules set for 31st won't repeat on months ending on or before the 30th",
  'work.warning.repeatOnShortMonth':
    'Repeats at end of short months will fall on the last day (e.g. 28th Feb, 30th Apr)',
}

export default enUS
