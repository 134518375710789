import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'
import Radium from 'radium'
import {
  chain,
  compact,
  concat,
  find,
  isFunction,
  map,
  noop,
  reduce,
  sortBy,
} from 'lodash'
import { withTranslation } from 'react-i18next'

import FilterMenuButton from 'components/filter-next/filter-menu-button'
import FilterDropdown from 'components/filter-next/filter-dropdown'
import ResultCount from 'components/filter-next/result-count'
import FilterTags from 'components/filter-next/filter-tags'
import FilterSearch from 'components/filter/search'
import Flex from 'components/common/flex'
import SelectedCount from 'components/filter-next/selected-count/SelectedCount'

import styles from './styles'

export default compose(
  withState('dropdownVisible', 'setDropdownVisibility'),
  withState('selectedCategory', 'setCategory'),
  withHandlers({
    resetDropdownState,
  }),
  withHandlers({
    toggleDropdown,
  }),
  withHandlers({
    getCategories,
    getCategoryOptions,
    handleSetCategory,
    handleSetSearch,
    handleSetValue,
  }),
  withTranslation(),
  Radium
)(FilterMenu)
function FilterMenu(props) {
  const {
    categories,
    dropdownVisible,
    enableCount = true,
    enableFilter = true,
    enableSearch = false,
    getCategories = noop,
    getCategoryOptions = noop,
    handleSetCategory = noop,
    handleSetSearch = noop,
    handleSetValue = noop,
    isResolving,
    onClear = noop,
    onClearAll = noop,
    resultsCount = 0,
    search = '',
    selected, // TODO more verbose naming
    selectedCategory = false,
    toggleDropdown = noop,
    totalCount = 0,
    rowsSelected,
    clearBulkSelection,
    isLighthouseAdmin,
    t,
  } = props

  const tags = buildTags(categories, selected)
  const fetchItems = selectedCategory ? getCategories : getCategoryOptions
  const changeHandler = selectedCategory ? handleSetValue : handleSetCategory
  return (
    <div style={styles.root}>
      <Flex flexDirection="row" flexBasis="auto">
        {enableSearch && (
          <div style={styles.searchContainer}>
            <FilterSearch
              dataTestId="search-field"
              onChange={handleSetSearch}
              placeholder={t('placeholder.search_ellipsis')}
              search={search}
              noBorder
            />
          </div>
        )}
        <div style={enableFilter ? styles.filterContainer : styles.hidden}>
          <FilterMenuButton onClick={toggleDropdown} />
          {dropdownVisible && (
            <FilterDropdown
              category={selectedCategory}
              currentFilters={selected}
              enableSearch={!!selectedCategory}
              fetchItems={fetchItems}
              onClear={onClear}
              onOuterClick={toggleDropdown}
              onSet={changeHandler}
            />
          )}
        </div>
        <FilterTags tags={tags} onClear={onClear} onClearAll={onClearAll} />
        {isLighthouseAdmin && (
          <SelectedCount
            rowsSelected={rowsSelected}
            totalCount={totalCount}
            onClick={clearBulkSelection}
          />
        )}
        <ResultCount
          enableCount={enableCount}
          isResolving={isResolving}
          resultsCount={resultsCount}
          totalCount={totalCount}
        />
      </Flex>
    </div>
  )
}

FilterMenu.propTypes = {
  categories: PropTypes.object.isRequired,
  dropdownVisible: PropTypes.bool.isRequired,
  enableCount: PropTypes.bool,
  enableFilter: PropTypes.bool,
  enableSearch: PropTypes.bool,
  getCategories: PropTypes.func,
  getCategoryOptions: PropTypes.func,
  handleSetCategory: PropTypes.func.isRequired,
  handleSetSearch: PropTypes.func,
  handleSetValue: PropTypes.func.isRequired,
  isResolving: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  resultsCount: PropTypes.number,
  search: PropTypes.string,
  selected: PropTypes.object, // TODO more verbose naming
  selectedCategory: PropTypes.string,
  toggleDropdown: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  clearBulkSelection: PropTypes.func,
  rowsSelected: PropTypes.array,
  isLighthouseAdmin: PropTypes.bool,
}

function buildTags(categories, selected) {
  return reduce(
    selected,
    (accum, values, categoryId) => {
      const category = categories[categoryId]

      if (!category) return accum

      const categoryItems = category.options
      const categoryLabel = category.label

      const categoryTags = map(values, value => {
        const matchedOption = find(categoryItems, { value })

        if (!matchedOption) {
          console.debug('Could not find matching option for selected value', {
            categoryItems,
            categoryLabel,
            value,
          })

          return null
        }

        return {
          category: {
            id: categoryId,
            label: categoryLabel,
          },
          value: {
            id: value,
            label: matchedOption.label,
          },
        }
      })

      return concat(accum, compact(categoryTags))
    },
    []
  )
}

function getCategoryOptions(props) {
  const { categories } = props

  return () => {
    const options = chain(categories)
      .pickBy('label')
      .map((category, categoryId) => ({
        value: categoryId,
        label: category.label,
      }))
      .value()

    const sortedOptions = sortBy(options, 'label')

    return Promise.resolve(sortedOptions)
  }
}

function getCategories(props) {
  const { categories, selected, selectedCategory } = props

  return () => {
    const category = categories[selectedCategory]
    const categoryItems = isFunction(category.getItems)
      ? category.getItems()
      : category.options

    return Promise.resolve(categoryItems).then(items => sortBy(items, 'label'))
  }
}

function handleSetCategory(props) {
  return obj => props.setCategory(obj.value)
}

function handleSetSearch(props) {
  return text => {
    props.onSearch(text)
  }
}

function handleSetValue(props) {
  const { onSet = noop } = props

  return obj =>
    onSet({
      category: props.selectedCategory,
      value: obj.value,
    })
}

function resetDropdownState(props) {
  return () => {
    props.setCategory('')
  }
}

function toggleDropdown(props) {
  const isVisible = props.dropdownVisible
  return () => {
    props.setDropdownVisibility(!isVisible)

    // Clear the category / reset dropdown on toggle

    props.resetDropdownState()
  }
}
