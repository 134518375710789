import React from 'react'
import { get } from 'lodash'

import TemplateEntry from 'components/templates/entry'
import QuestionWrapper from './question/question-wrapper'

export default function AuditTemplateEntry(props) {
  const {
    form,
    formValues,
    groupScores,
    isEditing,
    readOnly,
    templateEntity,
  } = props

  const isExistingAudit = !!templateEntity._id
  const showErrorOnUnTouched = isEditing && isExistingAudit

  return (
    <>
      <TemplateEntry
        dataTestIdPrefix="header"
        form={form}
        formValues={formValues.headerFields}
        headerFields={templateEntity.headerForm}
        isEditing={isEditing}
        readOnly={readOnly}
        small
        showErrorOnUnTouched={showErrorOnUnTouched}
        templateEntity={templateEntity.headerForm}
        name="headerFields.formGroups"
      />
      <QuestionWrapper
        form={form}
        isEditing={isEditing}
        readOnly={readOnly}
        small
        templateEntity={templateEntity}
        items={templateEntity.items}
        formValues={formValues}
        groupScores={groupScores}
      />
      <TemplateEntry
        dataTestIdPrefix="footer"
        form={form}
        formValues={formValues.footerFields}
        footerFields={templateEntity.headerForm}
        isEditing={isEditing}
        readOnly={readOnly}
        showErrorOnUnTouched={showErrorOnUnTouched}
        small
        templateEntity={templateEntity.footerForm}
        name="footerFields.formGroups"
      />
    </>
  )
}
